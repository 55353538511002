import React from "react"
import {Container} from "react-bootstrap"
import SEO from "../components/seo"
import Layout from "../components/layout";
import {graphql} from "gatsby";

const TermsAndConditions = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title={data?.terms?.nodes[0]?.metaForPage?.title ?? "Terms and Conditions"} description={data?.terms?.nodes[0]?.metaForPage?.description} />

            <Container className="terms-and-conditions pt-6 pb-6">
                {data?.terms?.nodes?.map((item) => (
                    <div dangerouslySetInnerHTML={{ __html: item.content}}></div>
                ))}
            </Container>

        </Layout>
    )
}

export const data = graphql`
    query Terms{
        terms: allWpPage(filter: {
            slug: {
                eq: "terms-and-conditions"
            }
        }
        ){
            nodes {
              content
              metaForPage {
                title
                description
            }
            }
          }
    }
`
export default TermsAndConditions
